:root {
  --primaryhover: #916540;
  --btnColor: #000000;
  --background: #eff9f7;
  --primary: #131218;
  --white: #ffffff;
  --black: #000000;
  --darkbg: #26272c;
  --darktext: #6c757d;
  --lightwhite: #ffffff;
  --darkbg2: #35363c;
  --darkbg3: #1d1e25;
  --green: #009946;
  --red: #ff4141;
}
body {
  background-color: var(--primary);
}
.ant-layout {
  background-color: var(--primary) !important;
  height: 100%;
  min-height: 100vh;
  max-height: 100%;
}
p {
  @apply font-inter;
}
a:hover {
  color: white !important;
}
.sidebar aside.ant-layout-sider.ant-layout-sider-dark {
  background: var(--darkbg) !important;
  border-radius: 0px;
  margin: 15px;
}
.sidebar .ant-menu {
  background-color: var(--darkbg);
  color: var(--white);
  margin-top: 20px !important;
  margin-bottom: 20px !important;

  @apply font-inter;
}
/* .sidebar .ant-layout {
  width: 100% !important;
} */
.sidebar .ant-menu-submenu-title {
  color: #fff !important;
}

.sidebar .ant-menu .ant-menu-item-selected {
  background-color: var(--primaryhover) !important;
  color: var(--white) !important;
}
.sidebar .ant-menu .ant-menu-item-selected:hover {
  background-color: var(--primaryhover) !important;
  color: var(--white) !important;
}
.sidebar .ant-layout-header {
  padding-inline: 0;
}
.sidebar .ant-menu-item:hover {
  background-color: var(
    --primary
  ) !important; /* when hovering over on an item */
}

.sidebar li.ant-menu-item:active {
  background-color: var(--white) !important;
}
.sidebar .ant-menu .ant-menu-item-selected:active {
  background-color: var(--primary) !important;
}

.sidebar .ant-menu .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: var(--white) !important;
}
.sidebar .ant-menu-submenu .ant-menu-inline {
  background: var(--darkbg) !important;
  border-radius: 10px !important;
  /* margin:0px 15px 0px 15px !important; */
}
.ant-menu-submenu-popup .ant-menu .ant-menu-item {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: var(--white);
  color: #000;
}
.ant-menu-submenu-popup
  .ant-menu
  .ant-menu-submenu
  .ant-menu-submenu-title
  .ant-menu-title-content
  p,
.ant-menu-submenu-arrow {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: var(--darkbg);
  color: #000 !important;
}
.ant-layout-sider-collapsed {
  width: 50px !important;
  min-width: 50px !important;
}
.ant-menu-submenu-popup .ant-menu .ant-menu-item-selected {
  background-color: var(--primary);
}
.ant-menu .ant-menu-sub {
  background-color: var(--white) !important;
}
/* .ant-menu .ant-menu-sub li{
  padding-left: 21px !important;
} */
.ant-menu-submenu-popup .ant-menu .ant-menu-item:hover {
  background-color: var(--primaryhover) !important;
}
.Nested_report .ant-menu-submenu-title {
  padding-left: 0px !important;
}
.Nested_report .ant-menu .ant-menu-sub .ant-menu-inline {
  margin: 0px !important;
}
/* scrolllbar1 */
::-webkit-scrollbar {
  width: 0px;
  height: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c8ced4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* table css */
.history-table thead.ant-table-thead tr th {
  background-color: var(--darkbg2);
  color: var(--white);
  font-weight: 700;
  padding: 10px 20px;
  font-size: 13px;
  text-align: center !important;
  /* Set your desired background color */
  /* Add any other styles you want for the table header */
}
.history-table th.ant-table-cell .ant-table-column-has-sorters {
  /* background-color: var(--bs-gray-100) !important; */
  text-align: center !important;
}
.history-table th.ant-table-cell {
  text-align: center;
  border-radius: 0px !important;
}
.history-table th.ant-table-cell::before {
  background-color: transparent !important;
}
.history-table .ant-table-tbody > tr > td {
  padding: 10px 20px;
  text-align: center;
  color: var(--lightwhite) !important;
  border: none !important;
  @apply font-dmsans capitalize;
}
.even-row {
  background: var(--darkbg2) !important; /* Light color for even rows */
}

.odd-row {
  background: var(
    --darktext
  ) !important; /* Default background color for odd rows */
}
.even-row .ant-table-cell-row-hover {
  background-color: var(--darkbg2) !important; /* Light color for even rows */
}

.odd-row .ant-table-cell-row-hover {
  background-color: var(
    --darktext
  ) !important; /* Default background color for odd rows */
}
.history-table .ant-table-tbody > tr {
  height: 2px;
  line-height: 24px;
}
.ant-table-filter-dropdown .ant-table-filter-dropdown-btns button:nth-child(2) {
  background-color: #113150 !important;
}
.history-table .ant-table-tbody {
  background-color: var(--darkbg);
}
.table-rows .ant-table-tbody {
  background-color: #162636;
  color: #ffffff !important;
}

.table-rows .ant-table-tbody > tr:hover {
  color: #000000;
}
/* SPINNER CLASSES */
.ant-spin-dot .ant-spin-dot-item {
  background-color: var(--primary);
}
.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(
    --primary
  ) !important; /* Change the background color to green when checked */
  border: none !important;
}
.ant-select-selector {
  border-radius: 0px !important;
}
.ant-checkbox-inner::after {
  border-color: white; /* Change the tick mark color to white */
}
.ant-skeleton-button {
  width: 275px !important;
  height: 100px !important;
}
.ant-switch {
  background-color: #c4c4c4;
}
.ant-switch-checked {
  background-color: var(--primary) !important;
}
.ant-form-item-label {
  padding: 0px !important;
}

.ant-input-disabled {
  color: white !important;
  background-color: var(--darkbg3) !important;
}

.ant-menu-submenu-arrow {
  color: white !important;
}
.ant-btn {
  color: white !important;
  border: none !important;
  outline: none !important;
  border-radius: 0px !important;
}

.ant-form-item-required {
  color: var(--primaryhover) !important;
}
.ant-btn-default:disabled {
  background-color: gray !important;
  color: white !important;
}
/* PAGINATION CSS */
.ant-pagination-item {
  background: none !important;
  @apply font-bold text-[#6C757D] font-inter;
}
.ant-pagination-item a {
  color: white !important;
}
.ant-pagination-item-active {
  border: none !important;
  background-color: var(--darkbg) !important;
  color: white !important;
  border-radius: 0px !important;
  @apply bg-darkbg text-white;
}
.ant-pagination-item-active a {
  color: white !important;
}

.ant-input {
  @apply bg-primary h-10 text-lightwhite border-none hover:bg-darkbg3 hover:shadow-none focus:shadow-none shadow-none focus:bg-darkbg3 rounded-lg;
}
.ant-input-number {
  @apply bg-primary h-10 text-lightwhite border-none hover:bg-darkbg3 hover:shadow-none focus:shadow-none shadow-none focus:bg-darkbg3 rounded-lg;
}
.ant-input-number .ant-input-number-input {
  @apply bg-primary h-10 text-lightwhite border-none hover:bg-darkbg3 hover:shadow-none focus:shadow-none shadow-none focus:bg-darkbg3 rounded-lg;
}
.ant-select-selector {
  background: var(--primary) !important;
  border-radius: 50px !important;
  color: white !important;
  border: none !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.ant-select-selector .ant-select-selection-placeholder {
  color: var(--darktext) !important;
}
.ant-select-dropdown {
  background-color: var(--darkbg) !important;
}
.ant-select-item {
  background-color: var(--darkbg2) !important;
  color: var(--lightwhite) !important;
}
.ant-select-disabled .ant-select-selector {
  background-color: var(--darkbg2) !important;
}
.ant-input::placeholder {
  color: rgb(141, 141, 141) !important;
}

.ant-modal-content {
  background-color: var(--darkbg2) !important;
  border-radius: 0px !important;
}
.ant-modal-header {
  background: transparent !important;
}
.ant-modal-title {
  color: white !important;
}

.ant-form-item-label label {
  color: white !important;
}
.ant-table-placeholder {
  background-color: transparent !important;
}
.anticon-close .ant-modal-close-icon {
  color: white !important;
}
.loginbg {
  background-image: url("./Controller/luckyzobg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.ant-modal-close-icon {
  color: white !important;
}

.apexcharts-legend-text {
  color: white !important;
}
.apexcharts-text {
  color: white !important;
}
.apexcharts-title-text {
  color: white !important;
}
.ant-picker-input input {
  color: white !important;
}
.ant-picker {
  background-color: var(--green) !important;
}
.ant-picker-range-separator .ant-picker-separator {
  color: white !important;
}
.chart-select {
  width: 200px !important;
  text-align: center !important;
}
.ant-select-selection-item {
  color: white !important;
}
.ant-form-item-explain-error {
  margin-top: 9px !important;
}
.ant-card .ant-card-body {
  padding: 5px;
  border-radius: 0 0 8px 8px;
}
